import React from "react"

import Layout from "src/components/layout"
import Head from "src/components/Head"
import {useTranslation} from "react-i18next"

import AffiliatesUpdateTop from "../components/affiliatesUpdate/AffiliatesUpdateTop/AffiliatesUpdateTop"

import { graphql } from "gatsby"
import AffiliatesList
  from "../components/affiliatesUpdate/AffiliatesList/AffiliatesList"

const AffiliatesUpdateMain = ({ location, data, pageContext }) => {
  const { t } = useTranslation()
  const attractions = pageContext.attractions
  const country = pageContext.country
  const brand = pageContext.brand;
  const lang = pageContext.lang;
  return (
    <Layout
      country={country}
      hotLine={data.site.siteMetadata.countries[country].hotLine}
      location={location}
      language={lang}
      countrySwitchOff={true}
    >
      <Head
        lang={lang}
        title={t("Updates in {{brand}} services", { brand})}
        description={t("Updates and changes in {{brand}} services", { brand })}
        slug={pageContext.slug}
      />
      <AffiliatesUpdateTop type="affiliates" brand={brand} />
      <AffiliatesList affiliatesArray={attractions} />
    </Layout>
  )
}

export default AffiliatesUpdateMain

export const query = graphql`
  query AffiliatesUpdate {
    site {
      siteMetadata {
        senderPath
        countries {
          by {
            hotLine {
              tel
              title
            }
          }
          am {
            hotLine {
              tel
              title
            }
          }
          ru {
            hotLine {
              tel
              title
            }
          }
          ua {
            hotLine {
              tel
              title
            }
          }
          lt {
            hotLine {
              tel
              title
            }
          }
          cy {
            hotLine {
              tel
              title
            }
          }
        }
      }
    }
  }
`
